import { AccountDataType } from '@crema/types/models/account';

export const accountData: AccountDataType = {
  member: [
    {
      id: 1,
      title: 'Your Twitter',
      image: '/assets/images/avatar/A4.jpg',
      name: '@Johndeuo',
      email: '',
    },
    {
      id: 2,
      title: 'Your Github',
      image: '/assets/images/avatar/A6.jpg',
      name: '@Johndeuo',
      email: '',
    },
    {
      id: 3,
      title: 'Your Facebook',
      image: '/assets/images/avatar/A2.jpg',
      name: 'King Rox',
      email: '',
    },
    {
      id: 4,
      title: 'Your Google',
      image: '/assets/images/avatar/A14.jpg',
      name: '',
      email: 'johndeuo@gmail.com',
    },
    {
      id: 5,
      title: 'Your Github',
      image: '/assets/images/avatar/A6.jpg',
      name: '@Johndeuo',
      email: '',
    },
    {
      id: 6,
      title: 'Your Facebook',
      image: '/assets/images/avatar/A2.jpg',
      name: 'King Rox',
      email: '',
    },
  ],
  notification: {
    activity: [
      {
        id: 1,
        title: 'Email me when someone comments on my article',
        defaultChecked: true,
      },
      {
        id: 2,
        title: 'Email me when someone answers on my form',
        defaultChecked: true,
      },
      {
        id: 3,
        title: 'Email me when someone answers on my form',
        defaultChecked: false,
      },
    ],
    application: [
      {
        id: 1,
        title: 'News and announcements',
        defaultChecked: false,
      },
      {
        id: 2,
        title: 'Weekly product updates',
        defaultChecked: true,
      },
      {
        id: 3,
        title: 'Weekly blog digest',
        defaultChecked: false,
      },
    ],
  },
};

export default accountData;
